import React from 'react';
import '../../css/window.css';



export default class Window extends React.Component{
  constructor(props) {
    super();
    
    this.state={
        visible:1
    }

    
    setTimeout(() => {
      this.setState({visible:2})
    }, 10);
    
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isVisible !== this.state.isVisible) {
      if(nextProps.isVisible==false){
        this.setState({  visible:1 });
      }
    }
  }

 


  render(){
    return (
      <div id={this.props.id} key={this.props.id} className={"window v_"+this.state.visible} onClick={()=>global.closeWindow()}>
        <div className="window_body" onClick={(e)=>{
          e.preventDefault();
          e.stopPropagation();
        }}>
        <p className="close"onClick={()=>global.closeWindow()}>х</p>
        {this.props.Content}
        </div>
        
      </div>
    );
  }
}
