const Prep = [
    {
        title:"Стойка конкурная",
        img:"i (16).png",
        fit:"contain"
    },
    {
        title:"Откос Мини-1.1",
        img:"i (17).png",
        fit:"contain"
    },
    {
        title:"Откос Мини-1.2",
        img:"i (18).png",
        fit:"contain"
    },
    {
        title:"Откос Мини-2.1",
        img:"i (19).png",
        fit:"contain"
    },
    {
        title:"Откос Мини-2.2",
        img:"i (20).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.1",
        img:"i (21).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.2",
        img:"i (22).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.3",
        img:"i (23).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.4",
        img:"i (24).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.5",
        img:"i (25).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.6",
        img:"i (26).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.7",
        img:"i (27).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.8",
        img:"i (28).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.9",
        img:"i (29).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.11",
        img:"i (30).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.11",
        img:"i (31).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.12",
        img:"i (32).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-1.13",
        img:"i (33).png",
        fit:"contain"
    },
    

    {
        title:"Откос ТИП-2.1",
        img:"i (34).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.2",
        img:"i (35).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.3",
        img:"i (36).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.4",
        img:"i (37).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.5",
        img:"i (38).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.6",
        img:"i (39).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.7",
        img:"i (40).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.8",
        img:"i (41).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.9",
        img:"i (42).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.10",
        img:"i (43).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-2.11",
        img:"i (44).png",
        fit:"contain"
    },

    {
        title:"Откос ТИП-3.1",
        img:"i (45).png",
        fit:"contain"
    },
    {
        title:"Откос ТИП-3.2",
        img:"i (46).png",
        fit:"contain"
    },


    {
        title:"Заложение ТИП-1.1",
        img:"i (47).png",
        fit:"contain"
    },
    {
        title:"Заложение ТИП-1.2",
        img:"i (48).png",
        fit:"contain"
    },
    {
        title:"Заложение ТИП-1.3",
        img:"i (49).png",
        fit:"contain"
    },


    {
        title:"Заборчик ТИП-1.1",
        img:"i (50).png",
        fit:"contain"
    },
    {
        title:"Заборчик ТИП-1.2",
        img:"i (51).png",
        fit:"contain"
    },
    {
        title:"Ливерпуль",
        img:"i (58).png",
        fit:"contain"
    },

]

export default Prep; 