import React from 'react';
import "../../../css/B2.css"
import Catalog from '../../Window/Catalog';

export default class B4 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B2 bb container-fluid ">
        <div className='row'>

          <div className="col-1 ticet_cont">
            <div className="ticet">
              <p>ДИЗАЙНЕРСКИЕ</p>
            </div>
          </div>

          <div className=" col-xl-5 col-lg-6 col-11 pl-5 pr-0">
            <h1 >Особые дизайнерские препятствия для конкура</h1>
            <p className="txt mt-3">В отличии от типовых препятствий
элементы дизайнерских препятствий не
имеют ограничений по геометрическим
формам и размерам, и во многом зависят
от пожелания заказчика. То же самое
можно сказать и про наполнение.
Неизменными остаются только размеры
прыжковой зоны. </p>
            <p className="but"  onClick={()=>global.openWindow(<Catalog Category="Препятствия"/>)}>КАТАЛОГ ТОВАРОВ</p>
            
          </div>

          <div className=" col-xl-6 col-lg-5 col-md-3">
            <img src="./img/design/pr3.jpg"/>
          </div>

        </div>
        <div class="alert alert-light" role="alert">
            Если вы хотите приобрести что-то особое. То, чего нет ни у кого - мы вместе с Вами можем воплатить Вашу мечту в реальность и создать что то совершенно новое. Наши дизайнеры будут работать Лично с Вами, а технологи разработают специальную схему производства именно для Вас
</div>
      </div>
    );
  }
}
