import React from 'react';
import "../../../css/B5.css"
import Contacts from '../../Window/Contacts'
import Catalog from '../../Window/Catalog';
export default class B5 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B5 bb container-fluid ">
        
          <div className="greenBar">
            <div className="container">
              <div className='row'>

                <div className="col-8">
                  <p className="m-0 p-0 mt-3"><b>Изготовление оборудования под заказ</b></p>
                  <p className="m-0 p-0">Наши дизайнеры помогут вам создать идеальный внешний вид поля</p>
                </div>
                <div className="col-4">
                  <div className="btn btn-dark float-right mt-4 text-dark" onClick={()=>global.openWindow(<Contacts/>)}>Оставить заявку</div>
                </div>

              </div>
            </div>
          </div>


        <div className="row cont">
          <div className="col-1">
            <div className="ticet">
              <p>СОЛЯРИИ</p>
            </div>
          </div>
          <div className="col-lg-5 col-md-8 col-11 pl-5 mt-5 pt-5">
            <h4 className="mt-5 pt-5 mb-3">Внимание к здоровью лошади</h4>
            <p>В условиях конюшенного содержания лошади  лишены достаточного количества солнечного света, особенно в холодное время года. Отсутствие необходимого ультрафиолета (UVB) ведет к уменьшению выработки организмом витамина D, дефицит которого особенно сказывается на жеребых кобылах и молодняке (кобылы часто абортируют, молодняк болеет рахитом). Без должного количества ультрафиолета у лошадей нарушается процесс линьки, наступает депрессия.   Инфракрасное излучение прогревает мускулатуру, благотворно влияет на здоровье и работоспособность. Использование солярия показано так же при лечении травм и растяжений у лошадей. </p>
            <p className="but light" onClick={()=>global.openWindow(<Catalog Category="Солярии"/>)}>КАТАЛОГ ТОВАРОВ</p>
          </div>
          <div className="col-1 d-none d-md-none d-lg-flex"></div>
          <div className="col-lg-5 col-md-3 m-0 p-0 d-none d-md-flex">
            <img className="bigImg" src="./img/design/B3.jpg"/>
          </div>
        </div>
        <img id="horse" src="./img/design/horse.png"/>
        <p id="bottomTXT" className="d-none d-sm-none d-md-flex">Лошадям нужен солярий не для загара, как людям, а для того, чтобы вырабатывался витамин D. Дело в том, что лошади по своей специфике часто содержатся в недостатке солнечного света, поэтому обязательно должны получать дозу ультрафиолета, находясь в солярии. Так что практически все конюшни, которые не могут позволить себе держать лошадей на улице достаточное время, используют такое оборудование.</p>

        
      </div>
    );
  }
}
