import React from 'react';
import '../../css/blocks.css';
import $ from "jquery"



import Block from "./Blocks/Block";
import B1 from "./Blocks/B1";
import B2 from "./Blocks/B2";
import B3 from "./Blocks/B3";
import B4 from "./Blocks/B4";
import B4_1 from "./Blocks/B4_1";
import B5 from "./Blocks/B5";
import B6 from "./Blocks/B6";
import B7 from "./Blocks/B7";
import B8 from "./Blocks/B8";
import B9 from "./Blocks/B9";
import B10 from "./Blocks/B10";


export default class MainPage extends React.Component{
  constructor(props) {
    super();
    
    this.state={
        selectedBlock:0
    }
    this.isScrolling=false;

    this.blocks=[
        <B1/>,
        <B2/>,
        <B3/>,
        <B4/>,
        <B4_1/>,
        <B5/>,
        <B6/>,
        <B7/>,
        <B8/>,
        <B9/>,
        <B10/>
    ]

    this.isMobile_var=this.isMobile();
    global.selectBlock=(number)=>this.selectBlock(number);
  }

  isMobile(){
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        return true;
    }else return false;
  }

  selectBlock(number){
    if(!this.isMobile_var)
      this.setState({selectedBlock:number})
    else{
      
      var top =$("#keyBlock_"+number)[0].parentNode.offsetTop;

      $('html').animate({
          scrollTop: top
      }, {
          duration: 3000,   // по умолчанию «400» 
          easing: "linear" // по умолчанию «swing» 
      });

    }
  }


  Scroll(e){
    if(this.isScrolling || this.isMobile_var) return;
    this.isScrolling=true;
    setTimeout(()=>this.isScrolling=false,1600);
      if(e.deltaY>0){
        if(this.state.selectedBlock<this.blocks.length-1)  
            this.selectBlock(this.state.selectedBlock+1)
      }else{
        if(this.state.selectedBlock>0)  
          this.selectBlock(this.state.selectedBlock-1)
      }
  }

  componentDidMount(){
  
  }

  render(){
    return (
      <div onTouchStart={()=>this.isTouchPad_var=true} id="scroller" className="MainPage blockCont" onWheel={(e)=>this.Scroll(e)}>
        <img src="./img/design/topArrow.svg" id="topArrow" className={this.state.selectedBlock==0 && !this.isMobile_var?"hidden":""} onClick={()=>global.selectBlock(0)}/>
        {this.blocks.map((bl,i)=>{
 
                if(this.state.selectedBlock==i)
                    return <Block Content={bl} Position={1}/>
                else{
                    if(this.state.selectedBlock>i)
                        return <Block Content={bl} Position={0}/>
                    else 
                        return <Block Content={bl} Position={2}/>
                }
                    

            }
        )}
        
      </div>
    );
  }
}
