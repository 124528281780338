import React from 'react';
import "../../../css/B2.css"
import Catalog from '../../Window/Catalog';

export default class B3 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B2 bb container-fluid ">
        <div className='row'>

          <div className="col-1 ticet_cont">
            <div className="ticet">
              <p>ТРЕНИРОВОЧНЫЕ</p>
            </div>
          </div>

          <div className=" col-xl-5 col-lg-6 col-11 pl-5 pr-0">
            <h1 >Оборудование для тренировок</h1>
            <p className="txt mt-3">Все представленные ниже откосы, стойки, заборчики, заложения выполнены из алюминиевой трубы
прямоугольного сечения и окрашены порошковой краской компании Faproxyd. Элементы заполнения откосов,
заборчиков, заложений изготовлены из вспененного ПВХ компании PALARM или окрашенной влагостойкой
фанеры. Жерди изготовлены из оцилиндрованного бруса сосны или ели, шпатлеваны, окрашены ударостойкой
тиксотропной алкидной эмалью компании Tikkurila различных цветов в форме «браслет». Калабашки и конкурные рейки выполнены из листовой стали и оцинкованы.

</p>
            <p className="but"  onClick={()=>global.openWindow(<Catalog Category="Препятствия"/>)}>КАТАЛОГ ТОВАРОВ</p>
            
          </div>

          <div className=" col-xl-6 col-lg-5 col-md-3">
            <img src="./img/design/pr2.jpg"/>
          </div>

        </div>
        <div class="alert alert-light" role="alert">
            Элементы Тренировочных препятствий
отличаются от Турнирных габаритными
размерами и простотой изготовления, и,
как следствие, более низкой ценой.
Высота таких препятствий не превышает
160 см, ширина Откосов варьируется в
диапазоне 45 — 60 см, а длина жердей,
досок, заборчиков, заложений не
превышает 3,0 м.
Уменьшенные габаритные размеры
особенно актуальны в зимнее время в
условиях ограниченного пространства
манежей. В остальном они полностью
отвечают всем требованиям и стандартам «Правил соревнований по конкуру» и
могут быть использованы для проведения любительских соревнований.
Основой всех тренировочных препятствий являются два типа откосов и
конкурная стойка.
</div>
      </div>
    );
  }
}
