 import React from 'react';
import "../../../css/B1.css"
import Catalog from '../../Window/Catalog';

export default class B1 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B1 bb  p-0" id="keyBlock_0">
        <div id="imgs">
          <img className="bigImg" src="./img/design/bg1.jpg"/>
          <div id='line'/>
          <img className="bigImg" id="horse" src="./img/design/bg1.png"/>
          
        </div>
        <div id="treg"/>
        <div id="txt">
          <h1 id='fav'>FAVOR<f>IT</f></h1>
          <p id="desig">Design Studio</p>
          <p className="menus" onClick={()=>global.openWindow(<Catalog Category="Препятствия"/>)}>Препятствия</p>
          <p className="menus" onClick={()=>global.openWindow(<Catalog Category="Оборудование"/>)}>Оборудование</p>
          <p className="menus" onClick={()=>global.openWindow(<Catalog Category="Солярии"/>)}>Солярии</p>
          <p className="menus" onClick={()=>global.selectBlock(10)}>Контакты</p>
        </div>
        
      </div>
    );
  }
}
