import React from 'react';



export default class Contacts extends React.Component{
  constructor(props) {
    super();
    
    this.state={
      
    }
this.input=[]
   
    
  }

  send(){
    var text={}
    var THIS = this;
    
    text[":USLUGA"]="Консультация по телефону";
    var keys = Object.keys(this.input);
    keys.map((key)=>{
      text[":"+key]=this.input[key].value
    })

    
    
    global.API_task("http://3.dsf.z8.ru/API/fileManager/sendMail.php",{
      EMAIL:"dodoma26@gmail.com",
      FROM:"Favorit",
      SHABLON:"Mail",
      TEXT:text
    },(result)=>{
      console.log(result);
      global.closeWindow();
    })
  }

  render(){
    return (
      <div className="Contacts centered">
        <div className="row mt-3">
            <div className="col-1 col-lg-3"/>
            <div className="col-10 col-lg-6">
                <h3>Заказ обратного звонка</h3>
                <p>Мы с радостью ответитм на все интересующие Вас вопросы</p>
                <input ref={ref => this.input.FIO = ref}  type="text" className="form-control" autoComplete="new-password" placeholder="Как Вас зовут?"/>
                <input ref={ref => this.input.PHONE = ref}  type="phone" className="form-control mt-3" autoComplete="new-password" placeholder="Ваш номер телефона"  onKeyUp={(e)=>{if(e.target.value.substr(0,2)!='+7') e.target.value=e.target.value!="+"?"+7"+e.target.value:"+7"}}/>
                <p className="but mt-4 mx-auto" onClick={()=>this.send()}>ЗАКАЗАТЬ ЗВОНОК</p>
            </div>
            <div className="col-1 col-lg-3"/>
        </div>
      </div>
    );
  }
}
