const Uslugi = [
    {
        title:"Восстановление жердей",
        price:"от 5 800",
        img:"wood.jpg"
    },
    {
        title:"Оформление поля",
        price:"от 1 150 800",
        img:"field.jpg"
    },
    {
        title:"Создание условий для конюшни",
        price:"от 2 400 000",
        img:"cons.jpg"
    }
]

export default Uslugi; 