const Oborud = [
    {
        title:"ТУМБЫ НАГРАДНЫЕ",
        img:"i (4).png"
    },
    {
        title:"Борона для конных полей",
        img:"i (5).png",
        fit:"contain"
    },
    {
        title:"Кронштейн для попон и вальтрапов",
        img:"i (6).png",
        fit:"contain"
    },
    {
        title:"Кронштейн для уздечки",
        img:"i (7).png",
        fit:"contain"
    },
    {
        title:"Навесной кронштейн",
        img:"i (8).png",
        fit:"contain"
    },
    {
        title:"Кронштейн для седла",
        img:"i (9).png",
        fit:"contain"
    },
    {
        title:"Кронштейн для попон",
        img:"i (10).png",
        fit:"contain"
    },
    {
        title:"Кронштейн для сушки, проветривания и хранения попон",
        img:"i (11).png",
        fit:"contain"
    },
    {
        title:"Складной кронштейн для седла",
        img:"i (12).png",
        fit:"contain"
    },
    {
        title:"Водилка для лошадей-18/6",
        img:"i (13).png"
    },
    {
        title:"Калабашка металлическая",
        img:"i (14).png",
        fit:"contain"
    },
    {
        title:"Рейка конкурная",
        img:"i (15).png",
        fit:"contain"
    },


    {
        title:"Жердь не обработанная",
        img:"i (53).png",
        fit:"contain"
    },
    {
        title:"Жердь обработанная",
        img:"i (54).png",
        fit:"contain"
    },
    {
        title:"Доска",
        img:"i (55).png",
        fit:"contain"
    },
    {
        title:"Кавалетти боковина",
        img:"i (56).png",
        fit:"contain"
    },
    {
        title:"Кавалетти в сборе",
        img:"i (57).png",
        fit:"contain"
    },
    
    {
        title:"Стелаж для хранения жердей 1",
        img:"i (59).png",
        fit:"contain"
    },
    {
        title:"Стелаж для хранения жердей 2",
        img:"i (60).png",
        fit:"contain"
    },
]

export default Oborud; 