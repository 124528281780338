const Solar = [
    {
        title:"Солярий для лошадей Комплекс HS-2L",
        img:"i (1).png"
    },
    {
        title:"Солярий для лошадей Комплекс HS-3L",
        img:"i (2).png"
    },
    {
        title:"Солярий для лошадей «SOLARIS МAXI»",
        img:"i (3).png"
    }
]

export default Solar; 