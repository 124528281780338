import React from 'react';
import "../../css/Catalog.css"
import Shop from "../../Shop"
import DopUsluga from '../../Pages/Window/DopUslugi';



export default class Catalog extends React.Component{
  constructor(props) {
    super();
    let Catalog = Shop();
    this.state={
      Categ:props.Category!=null?props.Category:Catalog[0].title,
      Catalog :Catalog
    }
   
    
  }

 
  componentDidMount(){
    
  }

  filterCateg(){
    var cat_=null;
    this.state.Catalog.map((cat)=>{
      
      if(cat.title==this.state.Categ){
        cat_= cat
      }
    })
    return cat_;
  }

  render(){
   let selected_categ=this.filterCateg();


   let list,list_categ=null;
   if(selected_categ!=null){
    list = selected_categ.items.map((item)=>{
      return(
        <div key={item.id} className="col item">
          <img src={"./img/shop/"+item.img} style={{objectFit:item.fit}}/>
            <img className="bay float-right" src="./img/design/shopping-cart.svg"
            onClick={()=>global.openWindow(<DopUsluga Usluga={"Купить "+item.title}/>)}/>
            <h4>{item.title}</h4>
            <p>{item.price!=null?item.price+"p":""}</p>

        </div>
      )
    })
    
  }

  list_categ=this.state.Catalog.map((categ)=>
        <li className="nav-item">
          <a className={"btn "+ (categ.title==this.state.Categ?" btn-light disabled":"")} 
          onClick={()=>this.setState({Categ:categ.title})}>{categ.title}</a>
        </li>
      )

 

    return (
      <div className="Catalog mt-2 container">

        <h3>Каталог товаров и услуг</h3>
        <p>Представлены типовые товары нашего произвоства. По вопросам преобретения с Вами свяжется наш менеджер</p>

        <ul class="nav ">
          {list_categ}
        </ul>

        <div className="row mt-3  row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4">
           {list}
        </div>
      </div>
    );
  }
}
