import React from 'react';



export default class DopUsluga extends React.Component{
  constructor(props) {
    super();
    
    this.state={
      
    }
    this.input=[];
  }

  send(){
    var text={}
    var THIS = this;
    
    text[":USLUGA"]=this.props.Usluga;
    var keys = Object.keys(this.input);
    keys.map((key)=>{
      text[":"+key]=this.input[key].value
    })

    
    
    global.API_task("http://3.dsf.z8.ru/API/fileManager/sendMail.php",{
      EMAIL:"dodoma26@gmail.com",
      FROM:"Favorit",
      SHABLON:"Mail",
      TEXT:text
    },(result)=>{
      console.log(result);
      global.closeWindow();
    })
  }

  render(){
    return (
      <div className="Contacts centered">
        <div className="row mt-3">
            <div className="col-3"/>
            <div className="col-6">
                <h3>Заказ индивидуальных услуг</h3>
                <p>Будьте уверены, нам есть, что предложить именно Вам</p>
                <input type="text" className="form-control" ref={ref => this.input.FIO = ref} autoComplete="new-password" placeholder="Как Вас зовут?"/>
                {this.props.Usluga==null?
                  <textarea ref={ref => this.input.USLUGA = ref} type="text" className="form-control" autoComplete="new-password" placeholder="Какая услуга Вас интересует?"></textarea>
                  :null}
                <input type="phone" ref={ref => this.input.PHONE = ref} className="form-control mt-3" autoComplete="new-password" placeholder="Ваш номер телефона"  onKeyUp={(e)=>{if(e.target.value.substr(0,2)!='+7') e.target.value=e.target.value!="+"?"+7"+e.target.value:"+7"}}/>
                <p className="but mt-4 mx-auto" onClick={()=>this.send()}>ЗАКАЗАТЬ КОНСУЛЬТАЦИЮ</p>
            </div>
            <div className="col-3"/>
        </div>
      </div>
    );
  }
}
