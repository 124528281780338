import React from 'react';
import "../../../css/B8.css"

export default class B8 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B8 bb container pt-5">
        <img id="h1" src='./img/design/h1.jpg'/>
        
        <div className="row mt-5">
        
          <div className="col-lg-7 text-right">
          <div id="lin"/>
            <h3>О нас</h3>
            <p>Мы - компания единомышленников. Мы делаем не толкьо на продажу, но и для себя, поэтому Вы можете не смневаться в высочайшем качестве производимого нами оборудования. Каждый из нас связал свою жизнь с конным спортом. Среди нас нет тех, кто ровнодушен к тому, что мы делаем. Мы работаем на результат. Мы открыты к сотрудничеству с такими же воодушивленными людьми как и мы сами</p>
          </div>
          <div className="col-lg-5 "></div>
        </div>


        <div className="row mt-5">
          <div className="col-lg-7 ">
            
          </div>
          <div className="col-lg-5 ">
            <h3>Участие в соревнованиях</h3>
            <p>Мы ежегодно учавствуем как в Российских так и зарубежных соревнованиях и выставках, посвященных конкуру. Для нас самое ценное - Имя бренда, и та уверенность в качестве, которую мы даем Вам</p>
          </div>
        </div>


        <div className="row mt-5">
          <div className="col-lg-7 text-right">
            <h3>Наши награды</h3>
            <p>Мы сертифицированы Минестерством спорта Росии как лучший производитель конкурного оборудования мирового уровня. Наше оборудование официально представленно в США, Германии и других странах. Так же мы стараемся развивать конный спорт и в небольших городах</p>
          </div>
          <div className="col-lg-5 "></div>
        </div>

      </div>
    );
  }
}
