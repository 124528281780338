import React from 'react';
import "../../../css/B2.css"
import Catalog from '../../Window/Catalog';

export default class B2 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
 

  render(){
    return (
      <div className="B2 bb container-fluid ">
        <div className='row'>

          <div className="col-1 ticet_cont">
            <div className="ticet">
              <p>СОРЕВНОВАТЕЛЬНЫЕ</p>
            </div>
          </div>

          <div className=" col-xl-5 col-lg-6 col-11 pl-5 pr-0">
            <h1 >Изготовление лучшего оборудования для конкурных соревнований</h1>
            <p className="txt mt-3">Только лучшие материалы применяются при изготовлении оборудования Favorit для проведения как тренировочных заездов, так и соревнований высших категорий. Наши работы признаны за рубежом и стоят на немецких выставках, достойно демонстрируя свой уровень. Наша продукция за многие годы завоевала доверие и уважение в кругах спортсменов и владельцев конных клубов. Мы вкладываем все силы, чтобы развивать данное направление дальше</p>
          
            <p className="but" onClick={()=>global.openWindow(<Catalog Category="Препятствия"/>)}>КАТАЛОГ ТОВАРОВ</p>
            
          </div>

          <div className=" col-xl-6 col-lg-5 col-md-3">
            <img src="./img/design/pr1.jpg"/>
          </div>

        </div>
        <div class="alert alert-light" role="alert">
            В качестве силовой основы типовых
препятствий используются стандартные
металлокаркасы, дополненные различными декоративными элементами.
Цветовая гамма и геометрия декоративных накладок могут различаться, но
габаритные размеры остаются неизменными.
Наполнение препятствий может быть
различным, и как правило, состоит из
двух откосов, двух заложений или одного
заборчика, от трех до пяти жердей, двух
досок и калабашек.
В случае создания широтных препятствий в комплект дополнительно
включаются два откоса или две конкурные стойки.
</div>
      </div>
    );
  }
}
