import React from 'react';
import './App.css';
import  "./GET";
import MainPage from "./Pages/Main/MainPage";
import Window from "./Pages/Window"


export default class App extends React.Component{
  constructor(props) {
    super();
    this.state={
      selectedPage:null,
      Windows:[]
      
    }
    this.pages={
      MainPage:<MainPage/>
    }

    if(global.GET.page==null)
      this.state.selectedPage=this.pages["MainPage"]
    else 
      if(this.pages[global.GET.page]!=null)
        this.state.selectedPage=this.pages[global.GET.page]
      else
        this.state.selectedPage=this.pages["MainPage"]


        global.openWindow=(Content)=>this.openWindow(Content);
        global.closeWindow=()=>this.closeWindow();
        global.API_task=(API_URL,GET_ARGS_ARRAY,callBack)=>this.API_task(API_URL,GET_ARGS_ARRAY,callBack);

  }
  API_task(API_URL,GET_ARGS_ARRAY,callBack){
    var ARGS="?";
   
      
      Object.keys(GET_ARGS_ARRAY).forEach(function (key) {
       
        if(ARGS!="?")
          ARGS+="&";
        var itJSON=false;
        try{
          if(JSON.stringify(GET_ARGS_ARRAY[key])[0]=="{"){
            itJSON=true;
          }
        }catch{}
        if(itJSON)
          GET_ARGS_ARRAY[key]=JSON.stringify(GET_ARGS_ARRAY[key]);
        ARGS=ARGS+key+"="+GET_ARGS_ARRAY[key]
        
      });
    
    fetch(API_URL+ARGS, {method: 'GET',headers: {
      'Content-Type': 'application/json;charset=utf-8'
    },})
      .then( ()=>callBack())
  }

  selectPage(page){
    this.setState({selectedPage:null})
    setTimeout(()=>this.setState({selectedPage:this.pages[page]}),1)
    
  }

  openWindow(Content){
    let windows = this.state.Windows;
    windows.push({
      id:Math.floor(Math.random() * Math.floor(1000)),
      WindowContent:Content,
      WindowVisible:true
    })
    this.setState({Windows:windows});
  }

  closeWindow(){
    let windows = this.state.Windows;
    windows[windows.length-1].WindowVisible=false;
    this.setState({Windows:windows});
    setTimeout(() => {
      let windows = this.state.Windows;
      windows.splice(windows.length-1,1);
      this.setState({Windows:windows});
    }, 500);
  }



  componentDidMount(){
  
  }

  render(){
    return (
      <div className="App ">
        {this.state.Windows.map((JSON)=>
          <Window id={JSON.id} Content={JSON.WindowContent} isVisible={JSON.WindowVisible}/>
        )}
        {this.state.selectedPage}
      </div>
    );
  }
}
