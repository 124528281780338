import React from 'react';
import "../../../css/B2.css"
import Catalog from '../../Window/Catalog';

export default class B4 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B2 bb container-fluid ">
        <div className='row'>

          <div className="col-1 ticet_cont">
            <div className="ticet">
              <p>РЕКЛАМНЫЕ</p>
            </div>
          </div>

          <div className=" col-xl-5 col-lg-6 col-11 pl-5 pr-0">
            <h1 >Рекламные препятствия для конкура</h1>
            <p className="txt mt-3">Форму, геометрические размеры,
цветовую гамму рекламных или спонсорских препятствий определяет Заказчик.
Со своей стороны, мы можем предложить
накопленный нами опыт в создании
подобных объектов.</p>
            <p className="but"  onClick={()=>global.openWindow(<Catalog Category="Препятствия"/>)}>КАТАЛОГ ТОВАРОВ</p>
            
            
          </div>
          
          <div className=" col-xl-6 col-lg-5 col-md-3">
            <img src="./img/design/pr1_.jpg"/>
          </div>

        </div>
        <div class="alert alert-light" role="alert">
            <b>Почему мы предпочитаем при изготовлении силовых и декоративных элементов препятствий алюминий и
пластик традиционному дереву и фанере?</b><br></br>
            Дело в том, что на сегодняшний день это наиболее подходящие материалы как с эксплуатационной точки
зрения, так и с точки зрения безопасности. Их стоимость на момент приобретения с лихвой окупается отсутствием необходимости в постоянных косметических ремонтах и низким весом (что особенно актуально в
детском и юношеском спорте). Алюминий и пластик практически не подвержены атмосферным воздействиям и
сохраняют свои декоративные и силовые свойства намного дольше, чем фанера и дерево. 

</div>
      </div>
    );
  }
}
