import React from 'react';
import "../../../css/B7.css"
import Contacts from '../../Window/Contacts'
import DopUsluga from '../../Window/DopUslugi';
import Catalog from '../../Window/Catalog';

export default class B7 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B7 bb container-fluid ">
        <div className="greenBar">
            <div className="container">
              <div className='row'>

                <div className="col-6">
                  <p className="m-0 p-0 mt-3"><b>Остались вопросы?</b></p>
                  <p className="m-0 p-0">Можете позвонить нашему менеджеру и он поможет вам их решить</p>
                </div>
                <div className="col-6">
                  <div className="btn btn-dark float-right mt-4 text-dark" onClick={()=>global.openWindow(<Contacts/>)}>Оставить заявку</div>
                </div>

              </div>
            </div>
          </div>

        <div className="row pt-5 mt-5 ">
          <div className="col-xl-3 col-md-2"/>
            <div className="col-xl-6  col-md-8 mt-3">
              <h3 className="text-center mb-3">Дополнительное услуги</h3>
              <p className="text-center">Мы предоставляем обширный спектор дополнительных услуг. Все наши коллеги или сами спортсмены или крайне вовлечены в конкур, поэтому мы всегда открыты к консалтингу и помощи в формированиии полей</p>
              <p className="but mx-auto" onClick={()=>global.openWindow(<Catalog Category="Услуги"/>)}>КАТАЛОГ УСЛУГ</p>
            </div>
            <div className="col-xl-3 col-md-2"/>
        </div>

        <div className="row    mt-2 pl-5 pr-5">
          <div className="col-md-4">
            <div class="card">
              <img src="./img/design/wood.jpg" class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">Восстановление жердей</h5>
                <p class="card-text">Чтобы не покупать новые жерди, Вы можете их восстановить у нас. У нас есть особая технология, которая может убрать все деффекты времени и Ваши жерди будут как новые</p>
                <a href="#" class="btn text-center btn-light pl-4 pr-4" onClick={()=>global.openWindow(<DopUsluga Usluga={"Восстановление жердей"}/>)}>Заказать</a>
              </div>
            </div>
           
          </div>
          <div className="col-md-4">
          <div class="card">
              <img src="./img/design/field.jpg" class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">Формирование поля</h5>
                <p class="card-text">Мы предоставляем услуги формирования выездкового поля. Все создается в соответствии с немецкими стандартами качества</p>
                <a href="#" class="btn text-center btn-light pl-4 pr-4" onClick={()=>global.openWindow(<DopUsluga Usluga={"Восстановление жердей"}/>)}>Заказать</a>
              </div>
            </div>
          </div>
          <div className="col-md-4">
          <div class="card">
              <img src="./img/design/cons.jpg" class="card-img-top" />
              <div class="card-body">
                <h5 class="card-title">Создание условий для конюшни</h5>
                <p class="card-text">Изготавливаем высококачественные вальеры и оборуем конюшню всем необходимым для здоровья Ваших лошадей</p>
                <a href="#" class="btn text-center btn-light pl-4 pr-4" onClick={()=>global.openWindow(<DopUsluga Usluga={"Восстановление жердей"}/>)}>Заказать</a>
              </div>
            </div>
          </div>
        </div>

      </div>
    );
  }
}
