const { get } = require("jquery");

global.GET=GETs(); 

function GETs() {
    let a = window.location.search;
    let b = new Object();
    a = a.substring(1).split("&");
   
    for (let i = 0; i < a.length; i++) {
    let c = a[i].split("=");
        b[c[0]] = c[1];
    }
    return b;
  }