import React from 'react';
import "../../../css/B8.css"

export default class B9 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B8 bb container pt-5">
        <img id="h2" src='./img/design/h2.jpg'/>
        

        <div className="row mt-5">
          <div className="col-lg-7 ">
          <div id="lin"/>
          </div>
          <div className="col-lg-5 ">
            <h3>Опыт</h3>
            <p>Все наше производство построено на многолетнем опыте. Если Вы хотите получить действительно качественный продукт, который прослужит вам долго и не вызывет никаких прериканий - покупайте у нас</p>
          </div>
        </div>


        <div className="row mt-5">
          <div className="col-lg-7 text-right">
            <h3>Почему нас выбирают</h3>
            <p>У нас практически нет единичных заказов. Многие из тех, кто познакомился лично с качеством нашего оборудования становятся постоянными клиентами, а мы, в свою очередь всегда открыты к сотрудничеству и индивидуальным условиям для каждого, с кем сотрудничаем</p>
          </div>
          <div className="col-lg-5 "></div>
        </div>


        <div className="row mt-5">
          <div className="col-lg-7 ">
            
          </div>
          <div className="col-lg-5 ">
            <h3>Участие в выставках</h3>
            <p>Наши препятствия укарашали красную площадь, стоят на выездковом поле Газпрома и во владении мера Москвы. нам доверяют, потому что мы всегда соблюдаем срок. Всегда делаем качественно. И всегда Вы получите от нас самое лучшее</p>
          </div>
        </div>

      </div>
    );
  }
}
