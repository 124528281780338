import React from 'react';
import "../../../css/B6.css"
import Catalog from '../../Window/Catalog';

export default class B6 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B6 bb container-fluid ">
        <div className="row">
          <div className="col-lg-7 col-md-6  p-0">
            <img className="bigImg" src='./img/design/hh.jpg'/>
          </div>
          <div className="col-lg-5 col-md-6  p-5">
            <h1 className="mb-3">Дополнительное оборудование</h1>
            <p>Так же мы изготавливаем дополнительное оборудования для конкура. Все, что необходимо как для оформления поля, так и для тренировок.
              Наше оборудование слвится своей надежностью и долговечностью. Мы используем только качественные материаллы, которые значительно продлевают срок службы изделий. Все наше оборудование легкое и даже хрупкой девушке не составит труда установить стойки, но в то же время, алюминиевый каркас крайне надежен и провен многолетним опытом.
            </p>
            <p className="but light" onClick={()=>global.openWindow(<Catalog Category="Оборудование"/>)}>КАТАЛОГ ТОВАРОВ</p>

          </div>
        </div>
      </div>
    );
  }
}
