import React from 'react';
import "../../../css/B10.css"
import Contacts from '../../Window/Contacts'

export default class B10 extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className="B10 bb container-fluid " id="keyBlock_10">
        <div className="row">
          <div className="col-lg-8 col-md-6 p-0">
            <img className="bigImg" src='./img/design/kk.jpg'/>
          </div>
          <div className="col-lg-4 col-md-6 p-5">
            <h1 className="mb-3" >Контактная информация</h1>
            <p>Alles, was wir tun, ist mit der Seele geschaffen</p>
            <p className="but"  onClick={()=>global.openWindow(<Contacts/>)}>ЗАКАЗАТЬ ОБРАТНЫЙ ЗВОНОК</p>
            <hr></hr>
            <p className="inf m-0 d-inline mr-3">+7(926) 699-70-17</p>
            <p className="inf d-inline float-right">favorplus@mail.ru</p>

            <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Ac96c87f48ee3b39cad89b47b7a7447e54d58666ffb8029fcba6a255335d2a8f1&amp;source=constructor" width="100%" height="371" frameborder="0"></iframe>
          </div>
        </div>
      </div>
    );
  }
}
