import Prep from "./Prepetstvia"
import Oborud from "./Oborud"
import Solar from "./Solar"
import Uslugi from "./Uslugi"
var id=1;
var Shops=[];
function Shop(){

    id=1;
    Shops=[];
    let cats=[
        {
            array:Prep,
            title:"Препятствия"
        },
        {
            array:Oborud,
            title:"Оборудование"
        },
        {
            array:Solar,
            title:"Солярии"
        },
        {
            array:Uslugi,
            title:"Услуги"
        }
        
    ]

    cats.map((cats,i)=>
        initing(cats.array,cats.title,i)
    )

   
    
  
   
   
  
    return Shops;
}

function initing(arr,arr_name,category_id){
       
    arr.map((item)=>{
        initItem(item,arr_name,category_id);
    })
}
function initItem(item,category,category_id){
    if(Shops[category_id]==null)
        Shops[category_id]={
            title:category,
            items:[]
        };
    item.id=id;
    Shops[category_id].items.push(item);
    id++;
}

export default Shop;