import React from 'react';


export default class Block extends React.Component{
  constructor(props) {
    super();
    this.state={
     
    }
    
  }
  componentDidMount(){
  
  }

  render(){
    return (
      <div className={"block position_"+this.props.Position}>
        {this.props.Content}
      </div>
    );
  }
}
